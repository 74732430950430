import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Power Snatch 4-4-4-4\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`CrossFit Open WOD 14.2`}</strong></p>
    <p>{`Every 3:00 for as long as possible complete:`}</p>
    <p>{`0:00-3:00`}</p>
    <p>{`2 rounds of`}</p>
    <p>{`10-Overhead Squats (95/65)`}</p>
    <p>{`10-CTB Pullups`}</p>
    <p>{`3:00-6:00`}</p>
    <p>{`2 rounds of`}</p>
    <p>{`12-OHS`}</p>
    <p>{`12-CTB Pullups`}</p>
    <p>{`6:00-9:00`}</p>
    <p>{`2 rounds of:`}</p>
    <p>{`14-OHS`}</p>
    <p>{`14-CTB Pullups`}</p>
    <p>{`etc, until you fail to complete 2 rounds in the 3:00 time period.`}</p>
    <p><strong parentName="p">{`*`}{`CrossFit Kids today at East from 9:30-10:00am.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The CrossFit Open starts February 23rd! Sign up now at:
Games.crossfit.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      